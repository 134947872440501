import React, { useState, useEffect } from "react";
import style from "./testimonials.module.css";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Space, Switch } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/editNew.svg";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import {
  getTestimonialsData,
  testimonialsDataDelete,
  sigleTestimonialsDataAction,
  blankDataAction,
  statusChangeAction,
  editBlankData,
} from "../../store/slices/testimonialsSlice";
import { mainUrl } from "../../utils/common";
import RevertLoader from "../../components/revertLoader/RevertLoader";

const Testimonials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.testimonials.data);
  const loading = useSelector((state) => state.testimonials.loading);
  const deleteLoad = useSelector((state) => state.testimonials.deleteLoad);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [ids, setIds] = useState();

  useEffect(() => {
    dispatch(getTestimonialsData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const columns = [
    // {
    //     title: 'Id',
    //     dataIndex: '_id',
    //     id: '_id',
    //     render: (id) => <a>{id}</a>,
    //   },
    {
      title: "Name",
      dataIndex: "name",
      id: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Message",
      dataIndex: "message",
      id: "message",
      // render: (text) => <a>{text}</a>,
      render: (text) => (
        <div
          style={{
            maxHeight: "4vh",
            maxWidth: "18ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text ? text : "NA"}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      id: "image",
      render: (image) => (
        <div className={style.common_style_image}>
          <img
            crossOrigin="anonymous"
            src={image ? `${mainUrl()}/${image}` : "-"}
            alt="image"
          />
        </div>
      ),
    },
    {
      title: "Work Image",
      dataIndex: "workImage",
      id: "workImage",
      render: (workImage) => (
        <div className={style.common_style_image}>
          <img
            crossOrigin="anonymous"
            src={workImage ? `${mainUrl()}/${workImage}` : "-"}
            alt="workImage"
          />
        </div>
      ),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      id: "designation",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Action",
      id: "action",
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandler(record)}
          >
            <img src={EditImg} alt="edit_image" />
            <div>edit</div>
          </div>
          {/* <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
              {deleteLoad && record._id=== deleteId ? <ColorLoader/>:
              <>
              <img src={DeleteImg} alt='edit image'/>
               <div>delete</div>
               </>
               }
              </div> */}
        </Space>
      ),
    },
  ];
  const addHandler = () => {
    dispatch(blankDataAction());
    dispatch(editBlankData());
    navigate("/testimonials/add-testimonials");
  };
  const editHandler = (record) => {
    dispatch(sigleTestimonialsDataAction(record._id));
    navigate(`/testimonials/edit-testimonials/${record._id}`);
  };
  const deleteHandler = (record) => {
    setDeleteId(record._id);
    dispatch(testimonialsDataDelete(record._id));
  };
  return (
    <>
      <Layout>
        <div className={style.testimonials_section}>
          <PageHeader
            name="testimonials details"
            btnName="add testimonials"
            img={AddImg}
            addHandler={addHandler}
          />
          {loading ? (
            <BigLoader />
          ) : (
            <>
              <Tables datas={state} columns={columns} />
              <div style={{ textTransform: "capitalize" }}>
                total testimonials -{" " + state ? state?.length : ""}
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Testimonials;
