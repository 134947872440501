import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BedImg from "../../assets/bed.png";
import DiningImg from "../../assets/dining.png";
import LivingImg from "../../assets/living.png";
import BigLoader from "../../components/bigLoader/BigLoader";
import CollectionPopup from "../../components/collectionPopup/CollectionPopup";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pageHeader/PageHeader";
import { getCollectionsData } from "../../store/slices/collectionsSlice";
import { mainUrl } from "../../utils/common";
import style from "./collections.module.css";

const Collections = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.collections.data);
  const loading = useSelector((state) => state.collections.loading);
  const message = useSelector((state) => state.collections.message);
  const [state, setState] = useState();
  const [datas, setDatas] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    dispatch(getCollectionsData());
  }, []);
  useEffect(() => {
    if (message === "Collection updated successfully!") {
      dispatch(getCollectionsData());
    }
  }, [message]);
  useEffect(() => {
    setState(data);
  }, [data]);
  const editHandler = (item) => {
    setShow(true);
    setDatas(item);
  };
  const closeHandler = () => {
    setShow(false);
  };
  return (
    <>
      <Layout>
        <div className={style.collections_section}>
          <PageHeader name="collections details" />
          {loading ? (
            <BigLoader />
          ) : (
            <div className={style.collections_details_container}>
              {state &&
                state.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={style.collections_details}
                      style={{
                        width:
                          state.length === 3
                            ? "32%"
                            : state.length === 2
                            ? "48%"
                            : "100%",
                      }}
                    >
                      <div
                        className={style.collections_details_head}
                        style={{
                          borderBottom:
                            item.title === "living room collections"
                              ? "1px solid #3BD755"
                              : item.title === "bed room collections"
                              ? "1px solid #168C98"
                              : "1px solid #3F45C8",
                        }}
                      >
                        <div
                          className={style.collections_details_head_img}
                          style={{
                            background:
                              item.title === "living room collections"
                                ? "#3BD755"
                                : item.title === "bed room collections"
                                ? "#168C98"
                                : "#3F45C8",
                          }}
                        >
                          <img
                            src={
                              item.title === "living room collections"
                                ? LivingImg
                                : item.title === "bed room collections"
                                ? BedImg
                                : DiningImg
                            }
                            alt="collection logos"
                          />
                        </div>
                        <div className={style.collections_details_head_label}>
                          {item.title}
                        </div>
                      </div>
                      <div
                        className={style.collections_details_bannerImg}
                        style={{
                          border:
                            item.title === "living room collections"
                              ? "1px solid #3BD755"
                              : item.title === "bed room collections"
                              ? "1px solid #168C98"
                              : "1px solid #3F45C8",
                        }}
                      >
                        <video autoPlay muted loop>
                          <source
                            src={
                              item.media ? `${mainUrl()}/${item.media}` : "-"
                            }
                            type="video/mp4"
                          />
                        </video>

                        <div className={style.collections_images_gallery}>
                          {item?.collectionImages && item?.collectionImages.map((img, index) => {
                            return (
                              <div
                                key={index}
                                className={style.collections_image_item}
                                style={{
                                  border:
                                    item.title === "living room collections"
                                      ? "1px solid #3BD755"
                                      : item.title === "bed room collections"
                                      ? "1px solid #168C98"
                                      : "1px solid #3F45C8",
                                }}
                              >
                                <img
                                  crossOrigin="anonymous"
                                  src={
                                    img.collectionImage
                                      ? `${mainUrl()}/${img.collectionImage}`
                                      : "-"
                                  }
                                  alt={`collection_image`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className={style.collections_details_desc}>
                        <p
                          style={{
                            color:
                              item.title === "living room collections"
                                ? "#3BD755"
                                : item.title === "bed room collections"
                                ? "#168C98"
                                : "#3F45C8",
                          }}
                        >
                          description
                        </p>
                        <div>{item.description}</div>
                      </div>
                      <div className={style.collections_details_footer}>
                        <div
                          className={style.delete_edit_hand_1}
                          onClick={() => editHandler(item)}
                          style={{
                            color:
                              item.title === "living room collections"
                                ? "#d68438"
                                : item.title === "bed room collections"
                                ? "#639cd9"
                                : "#7dd87d",
                            background:
                              item.title === "living room collections"
                                ? "#3BD755"
                                : item.title === "bed room collections"
                                ? "#168C98"
                                : "#3F45C8",
                          }}
                        >
                          <div>update</div>
                        </div>
                      </div>
                      {show ? (
                        <CollectionPopup
                          data={datas}
                          label="collection update"
                          closeHandler={closeHandler}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              <div style={{ textTransform: "capitalize", marginTop: "20px" }}>
                total collections -{" " + state ? state?.length : ""}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Collections;
