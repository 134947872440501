import React, { useState, useEffect } from "react";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  testimonialsDataAdded,
  testimonialsDataUpdate,
  testimonialsEditAction,
} from "../../store/slices/testimonialsSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import style from "./testimonialsChange.module.css";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import BackButton from "../../components/backButton/BackButton";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
const { TextArea } = Input;

const TestimonialsChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.testimonials.deleteLoad);
  const message = useSelector((state) => state.testimonials.message);
  const singleTestimonials = useSelector(
    (state) => state.testimonials.singleTestimonials
  );
  const editData = useSelector((state) => state.testimonials.editData);
  const [form] = Form.useForm();
  const urls = "/testimonials/add-testimonials";
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [addNewImage, setAddNewImage] = useState("");

  const [workImage, setWorkImage] = useState("");
  const [newWorkImage, setNewWorkImage] = useState("");
  const [addNewWorkImage, setAddNewWorkImage] = useState("");

  const [nameNew, setNameNew] = useState(""); // for Character limit
  const [designationNew, setDesignationNew] = useState(""); // for Character limit
  const [messageNew, setMessageNew] = useState(""); // for Character limit
  // for character limit
  const maxCharacters = 25;

  const handleChange = (event) => {
    const inputMessage = event.target.value;

    if (inputMessage.length <= maxCharacters) {
      setNameNew(inputMessage);
    }
  };

  // for character limit
  const maxDCharacters = 25;

  const handleDesignationChange = (event) => {
    const inputMessage = event.target.value;

    if (inputMessage.length <= maxDCharacters) {
      setDesignationNew(inputMessage);
    }
  };
  // for character limit
  const maxMCharacters = 85;

  const handleMessageChange = (event) => {
    const inputMessage = event.target.value;

    if (inputMessage.length <= maxMCharacters) {
      setMessageNew(inputMessage);
    }
  };
  useEffect(() => {
    setAddNewImage("");
    setAddNewWorkImage("");
  }, []);

  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(info?.file?.response?.data?.image.toString());
        setNewImage(info?.file?.response?.data?.image.toString());
        setAddNewImage(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };

  const propsWorkUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setWorkImage(info?.file?.response?.data?.image.toString());
        setNewWorkImage(info?.file?.response?.data?.image.toString());
        setAddNewWorkImage(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };

  const backHandler = () => {
    navigate("/testimonials");
  };
  const onFinish = (values) => {
    const data = {
      ...values,
      image:  newImage ? newImage : image,
      workImage: newWorkImage ? newWorkImage : workImage,
    };
    let datas = {...values, image: addNewImage, workImage: addNewWorkImage };
    if (path === urls) {
      dispatch(testimonialsDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(
        testimonialsDataUpdate(
          singleTestimonials[0]?._id ? singleTestimonials[0]?._id : id,
          data
        )
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "Testimonial added successfully!" ||
      message === "Testimoniel updated successfully!"
    ) {
      navigate("/testimonials");
    }
  }, [message]);
  useEffect(() => {
    let data = singleTestimonials[0];
    // form.setFieldValue("name",data?.name?data?.name:'')
    // form.setFieldValue("message",data?.message?data?.message:'')
    // form.setFieldValue("designation",data?.designation?data?.designation:'')
    setNewImage(data?.image ? data?.image.toString(): "");
    setNewWorkImage(data?.workImage ? data?.workImage.toString(): "");
  }, [singleTestimonials]);


  //for character limit
  useEffect(() => {
    form.setFieldValue("name", nameNew);
  }, [nameNew]);
  useEffect(() => {
    form.setFieldValue("designation", designationNew);
  }, [designationNew]);
  useEffect(() => {
    form.setFieldValue("message", messageNew);
  }, [messageNew]);

  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(testimonialsEditAction(id));
    }
  }, []);

  useEffect(() => {
    let data = editData;
    form.setFieldValue("name", data?.name ? data?.name : "");
    form.setFieldValue("message", data?.message ? data?.message : "");
    form.setFieldValue(
      "designation",
      data?.designation ? data?.designation : ""
    );
    setNewImage(data?.image);
    setNewWorkImage(data?.workImage ? data?.workImage : "");
  }, [editData]);
  const deleteImgHandler = () => {
    setNewImage("");
  };
  const deleteImgHandlerAdd = () => {
    setAddNewImage("");
  };

  const deleteWorkImgHandler = () => {
    setNewWorkImage("");
  };
  const deleteWorkImgHandlerAdd = () => {
    setAddNewWorkImage("");
  };

  //for character limit
  useEffect(() => {
    if (editData && editData.name) {
      setNameNew(editData.name);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.designation) {
      setDesignationNew(editData.designation);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.message) {
      setMessageNew(editData.message);
    }
  }, [editData]);
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            backButtonHandler={backHandler}
            name={path === urls ? "testimonials add" : "testimonials update"}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input
                value={nameNew}
                onChange={handleChange}
                placeholder="Enter your name"
              />
              <p>{`${nameNew.length}/${maxCharacters} characters`}</p>
              {/* <Input placeholder="Enter your name" /> */}
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Designation"
              name="designation"
            >
              <Input
                value={designationNew}
                onChange={handleDesignationChange}
                placeholder="Enter your designation"
              />
              <p>{`${designationNew.length}/${maxDCharacters} characters`}</p>
              {/* <Input placeholder="Enter your designation" /> */}
            </Form.Item>
            <div className={style.images_dimensions}>
              the image dimensions should be (200px)x(200px).
            </div>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
              }}
              label="Image"
              name="image"
            >
              {path === urls ? (
                <>
                  {addNewImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={
                            addNewImage ? `${mainUrl()}/${addNewImage}` : "-"
                          }
                          alt="edit_image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete_image"
                            onClick={deleteImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      onPreview={() => {}}
                      showUploadList={false}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImage ? `${mainUrl()}/${newImage}` : "-"}
                          alt="edit_image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete_image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Upload
                    action={trelifImageUpload}
                    listType="picture-card"
                    showUploadList={false}
                    onPreview={() => {}}
                    {...propsUpload}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newImage?.length < 1 && "+ Upload"}
                  </Upload>
                </>
              )}
            </Form.Item>

            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
              }}
              label="work Image"
              name="workImage"
            >
              {path === urls ? (
                <>
                  {addNewWorkImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={
                            addNewWorkImage ? `${mainUrl()}/${addNewWorkImage}` : "-"
                          }
                          alt="edit_image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete_image"
                            onClick={deleteWorkImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewWorkImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      onPreview={() => {}}
                      showUploadList={false}
                      {...propsWorkUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newWorkImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newWorkImage ? `${mainUrl()}/${newWorkImage}` : "-"}
                          alt="edit_image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete_image"
                            onClick={deleteWorkImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Upload
                    action={trelifImageUpload}
                    listType="picture-card"
                    showUploadList={false}
                    onPreview={() => {}}
                    {...propsWorkUpload}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newWorkImage?.length < 1 && "+ Upload"}
                  </Upload>
                  )}
                  
                </>
              )}
            </Form.Item>

            <Form.Item
              className={style.appointments_form_item}
              style={{ marginTop: "-25px" }}
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input message!",
                },
              ]}
            >
              <TextArea
                value={messageNew}
                onChange={handleMessageChange}
                rows={5}
                placeholder="message here...."
              />
              <p>{`${messageNew.length}/${maxMCharacters} characters`}</p>
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add testimonials"
                ) : (
                  "update testimonials"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default TestimonialsChange;
